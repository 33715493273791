@import (reference) './vars.less';

/**
    Class Reference

    .uf-action;

    Modifiers: small, large, bottom

*/

//---------------------------------------
//         Sizes

:root,
.default {
	--action-size: 2.5rem;
	--action-icon-size: @icon-size;
}

.large {
	--action-size: 3.75rem;
	--action-icon-size: @icon-size-lg;
}

.small {
	--action-size: 2rem;
	--action-icon-size: @icon-size-sm;
}

.x-small {
	--action-size: 1.75rem;
	--action-icon-size: @icon-size-xs;
}

.xx-small {
	--action-size: 1.5rem;
	--action-icon-size: @icon-size-xs;
}

//---------------------------------------
//         Colours

:root,
.uf-action.default,
.uf-action.secondary {
	--action-icon-color: @primary-action;
	--action-background: @secondary-action;
	--action-box-shadow: inset 0 0 0 2px @primary-action;

	--action-disabled-background: @secondary-action-disabled;
	--action-hover-background: @secondary-action-hover;
	--action-active-background: @secondary-action-pressed;
}

.uf-action.primary {
	--action-background: @primary-action;
	--action-box-shadow: none;
	--action-icon-color: white;

	// State
	--action-disabled-background: @primary-action-disabled;
	--action-hover-background: @primary-action-hover;
	--action-active-background: @primary-action-pressed;
}

.uf-action.tertiary {
	--action-icon-color: @icon-color;
	--action-background: @tertiary-action;
	--action-box-shadow: none;

	// state
	--action-disabled-background: none;
	--action-hover-background: @tertiary-action-hover;
	--action-active-background: @tertiary-action-pressed;
}

.uf-action.quaternary {
	--action-icon-color: @primary-action;
	--action-background: @tertiary-action;
	--action-box-shadow: none;

	// state
	--action-disabled-background: none;
	--action-hover-background: @tertiary-action-hover;
	--action-active-background: @tertiary-action-pressed;
}

//---------------------------------------
//          Disabled

.uf-action:disabled,
.uf-action.default:disabled,
.uf-action.secondary:disabled {
	--action-icon-color: @primary-action-disabled;
	--action-box-shadow: inset 0 0 0 2px @primary-action-disabled;
}

.uf-action.tertiary:disabled {
	--action-icon-color: @gray-40;
	--action-box-shadow: none;
}

.uf-action.primary:disabled {
	--action-icon-color: white;
	--action-box-shadow: none;
}


//---------------------------------------
//          Base

.uf-action {
	position: relative; // Required for badge placement
	display: flex;
	align-items: center;
	padding: 0;
	height: var(--action-size);
	width: var(--action-size);
	min-height: var(--action-size);
	min-width: var(--action-size);

	outline: 0;
	border-width: 0px;
	border-radius: 50%;
	-webkit-tap-highlight-color: transparent;
	outline: 0;
	line-height: initial;

	box-shadow: var(--action-box-shadow);
	transition: var(--action-transition, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms);
	background-color: var(--action-background);

	cursor: pointer;

	uf-icon {
		margin: auto;
		height: var(--action-icon-size);
		width: var(--action-icon-size);
		fill: var(--action-icon-color);
	}

	&:disabled {
		pointer-events: none;
		background-color: var(--action-disabled-background);
	}

	&:hover:not(:disabled) {
		background-color: var(--action-hover-background);
	}

	&:active:not(:disabled),
	&:focus:not(:disabled) {
		background-color: var(--action-active-background);
	}

	// Badge
	>.uf-badge {
		position: absolute;
		right: -6px;
		top: -6px;
	}
}

//---------------------------------------
//          Fixed

.uf-action.fixed {
	position: fixed;
	bottom: @gutter;
	right: @gutter;

	--action-box-shadow: inset 0 0 0 2px @primary-action, 0 10px 22px -5px rgba(0, 0, 0, 0.25);

	&:disabled {
		--action-box-shadow: inset 0 0 0 2px @primary-action-disabled, 0 10px 22px -5px rgba(0, 0, 0, 0.25);
	}

	&.tertiary,
	&.primary {
		--action-box-shadow: 0 10px 22px -5px rgba(0, 0, 0, 0.25);
	}

}


@media only screen and (min-width: 769px) {

	// Default
	.uf-action.fixed {
		bottom: @gutter*2;
		right: @gutter*2;
	}
}