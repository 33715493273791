@import 'vars.less';

// ---------------------------
//          Background

:root,
.uf-container.default {
  --container-background-colour: transparent;
  --container-padding-bottom: @space-10;
  --container-margin: 0 auto;
}


//------------------------------------
//          Container

*[class*='uf-container'] {
  margin: var(--container-margin, 0 auto);
  max-width: @screen-width-md;
  min-height: 100%;
  padding-bottom: var(--container-padding-bottom);

  background: var(--container-background-colour, white);

  >.uf-grid.wide {
    padding-left: @gutter;
    padding-right: @gutter;
  }
}

//------------------------------------
//          Set screen widths

.uf-container-sm {
  max-width: @screen-width-sm;
}

.uf-container-md {
  max-width: @screen-width-md;
}

.uf-container-lg {
  max-width: @screen-width-lg;
}

.uf-container-xl {
  max-width: @screen-width-xl;
}

//------------------------------------
//         Break points

@media (min-width: @screen-width-md) {

  *[class*='uf-container'] {
    padding-left: @gutter;
    padding-right: @gutter;
  }

  .uf-container-md,
  .uf-container-lg,
  .uf-container-xl,
  .uf-container {
    max-width: @screen-width-md;
  }
}

@media (min-width: @screen-width-lg) {

  .uf-container-lg,
  .uf-container-xl,
  .uf-container {
    max-width: @screen-width-lg;
  }
}

@media (min-width: @screen-width-xl) {

  .uf-container-xl,
  .uf-container {
    max-width: @screen-width-xl;
  }
}
