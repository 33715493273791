@import (reference) './vars.less';

// ------------------------
//  - align buttons to end
//  - space buttons
//  - allocate space for error message

.uf-form-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    padding: @space-3 @gutter;

    .message {
        margin-right: auto;
    }

    >button,
    >a {
        margin-bottom: @space-3;
        margin-top: @space-3;

        &:not(:first-child) {
            margin-left: auto;
            margin-left: @space-3;
        }
    }
}

.uf-error {
    flex-basis: 100%;
    min-height: @line-height-sm;

    color: @error;
    font-size: @font-size-xs;
    line-height: @line-height-sm;
}