@import 'vars.less';

// ---------------------------------------------------
// Dead simple helpers for ad-hoc flexing
// Do not add things here unless it is super generic
// Season with vanilla flex CSS for desired results
// ---------------------------------------------------

.row,
.col {
    display: flex;

    >* {
        flex-shrink: 0; // default to no shrinkage
    }
}

.col {
    flex-direction: column; // default is row
}

// helper for easily vertically spacing children
.col.space-children>*:not(:last-child) {
    margin-bottom: @gutter;
}

.col.space-children--small>*:not(:last-child) {
    margin-bottom: @space-3;
}

.row.space-children>*:not(:last-child) {
    margin-right: @gutter;
}

.row.space-children--small>*:not(:last-child) {
    margin-right: @space-3;
}

// in column mode we want content to push the container (flex-basis: auto)
.grow {
    flex-grow: 1;
}

// in row mode we want the content to wrap if it overflows the container
.row .grow {
    flex-basis: 0px;
}

.shrink {
    flex-shrink: 1;
}

// cross-axis
.stretch {
    align-self: stretch; //default is auto
}

// cross-axis
.center {
    align-self: center;
}

// cross-axis
.center-all {
    align-items: center; // default is stretch
}

.align-flex-end {
    align-items: flex-end;
}

.align-flex-start {
    align-items: flex-start;
}

// main-axis
.justify-center {
    justify-content: center; // default is flex-start
}

// wrap
.wrap {
    flex-wrap: wrap;
}


// ---------------------------
// Float helpers
// ---------------------------

.right{
    margin-left: auto;
}

.left{
    margin-right: auto;
}

.bottom {
    margin-top: auto;
}

.top {
    margin-bottom: auto;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

