@import (reference) './vars.less';

.generate-image(uf-img-2x1, 50%);
.generate-image(uf-img-3x2, 66.66%);
.generate-image(uf-img-16x9, 56.25%);
.generate-image(uf-img-4x3, 75%);
.generate-image(uf-img-1x1, 100%);

.generate-image(@className, @imgPadding) {

  .@{className} {
    position: relative;
    padding-top: @imgPadding;
    clear: both;

    background: var(--image-background, @gray-5);
    overflow: var(--image-overflow, initial);

    img,
    uf-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      max-width: 100%;
      min-width: var(--image-min-width, initial);
      max-height: var(--image-max-heigth, 100%);
      object-fit: contain;
      margin: auto;
    }

    img[src=""] {
      visibility: hidden;
    }

    uf-icon {
      height: 96px;
      width: 96px;
    }

    &.cover {
      --image-overflow: hidden;
      --image-max-heigth: initial;
      --image-min-width: 100%;
      img,
      uf-icon {
        object-fit: cover;
      }
    }

  }
}

// -----------------------------
// Deprecated use uf-img


div[class*='uf-image'] {
  position: relative;
  clear: both;
  background-color: @gray-5;

  img,
  uf-icon {
    display: block;
    margin: 0 auto;

    position: relative;
    width: 100%;

    padding: @space-3;
  }

  img[src=""] {
    visibility: hidden;
  }

  uf-icon {
    height: 96px;
    width: 96px;
  }

  &[class*='--no-padding'] {
    img {
      padding: 0;
    }
  }

  &[class*='--transparent'] {
    background: none;
  }

  // fixed ratios
  &[class*='--fixed-ratio'] {
    padding-top: 100%;

    img,
    uf-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      max-width: 100%;
      max-height: 100%;

      margin: auto;
    }

    img {
      width: auto;
    }

    // 16 by 9 images
    &[class*='--16by9'] {
      padding-top: 56.25%;
    }

    // 4 by 3 images
    &[class*='--4by3'] {
      padding-top: 75%;
    }

    // 3 by 2 images
    &[class*='--3by2'] {
      padding-top: 66.66%;
    }
  }

  &.right {
    float: right;
    margin-left: @gutter;
    padding: 0;
  }

  &.left {
    float: left;
    margin-right: @gutter;
    padding: 0;
  }
}