@import (reference) './vars.less';

//---------------------------------------
//          Modifiers

.type-sm-mixin() {

    h1,
    h2,
    h3,
    h4 {
        margin: 1.5rem 0 0.75rem; // 18px base:  1.33333rem 0 0.6666rem
    }

    h1 {
        font-size: 1.625rem; // 1.625rem
        line-height: 1.75rem;
    }

    h2 {
        font-size: 1.4rem; // 18px base: 1.244444rem
        line-height: 1.33333rem;
    }

    h3 {
        font-size: 1.25rem; // 1.11111rem;
        line-height: 1.4rem; // 1.244444rem
    }

    h4 {
        font-size: 1.125rem; // 1.11111rem;
        line-height: 1.4rem; // 1.244444rem
    }

    h5 {
        margin: 1rem 0 0.75rem; // 0.8888rem 0 0.6666rem;
        line-height: 1.125rem; // 1.11111rem;
    }

    h6 {
        margin: 1rem 0 0.75rem; // 0.8888rem 0 0.6666rem;
        line-height: 1rem; // 1.11111rem;
    }

    p {
        margin: 0.75rem 0 0.75rem;
    }

    blockquote {
        padding: @space-3;

        >* {
            margin: 0px;
        }
    }

}

.type-xs-mixin() {

    h1,
    h2,
    h3,
    h4 {
        margin: 1rem 0 0.75rem;
    }

    h1 {
        font-size: 1.5rem;
        line-height: 1.625rem;
    }

    h2 {
        font-size: 1.25rem;
        line-height: 1.4rem;
    }

    h3 {
        font-size: 1.125rem;
        line-height: 1.25rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1.125rem;
    }

    h5 {
        margin: 1rem 0 0.75rem;

        font-size: 0.875rem;
        line-height: 1.125rem;
    }

    h6 {
        margin: 1rem 0 0.75rem;

        font-size: 0.875rem;
        line-height: 1.125rem;
    }

    p {
        margin: 0.75rem 0 0.75rem;
    }

}

//---------------------------------------
//         Sizes

:root,
.default {
    --paragraph-font-size: @font-size;
    --paragraph-line-height: @line-height;

    --paragraph-colour: @gray-80;
    --heading-colour: @brand-accent;

    --label-font-size: @font-size-sm;
    --label-line-height: @line-height-sm;
}

.large {
    --paragraph-font-size: @font-size-lg;
    --paragraph-line-height: @line-height-lg;

    --label-font-size: @font-size;
    --label-line-height: @line-height;
}

.small {
    --paragraph-font-size: @font-size-sm;
    --paragraph-line-height: @line-height-sm;

    --label-font-size: @font-size-xs;
    --label-line-height: @line-height-xs;

    .type-sm-mixin();
}

.x-small {
    --paragraph-font-size: @font-size-xs;
    --paragraph-line-height: @line-height-xs;

    --label-font-size: @font-size-xs;
    --label-line-height: @line-height-xs;

    .type-xs-mixin();
}


//---------------------------------------
//          Mixins

// User for ul and ol items
.p-mixin() {
    font-size: var(--paragraph-font-size, @font-size);
    line-height: var(--paragraph-line-height, @line-height);
    margin: @gutter 0 @gutter 0;
    color: var(--paragraph-colour, @gray-80);

    word-break: break-word;
}


//---------------------------------------
//          Lists

.ul-mixin() {
    padding-left: @gutter + @space-2;
    margin: @gutter*.75 0;
    box-sizing: border-box;
    color: @gray-80;
}

.li-mixin() {
    box-sizing: border-box;
    padding-left: @space-3;
    font-size: var(--paragraph-font-size, @font-size);
    line-height: var(--paragraph-line-height, @line-height);

    list-style-position: outside;
}


//---------------------------------------
//          Navigation

.a-mixin() {
    font-size: var(--paragraph-font-size, @font-size);
    line-height: var(--paragraph-line-height, @line-height);
    text-decoration: underline;
    color: @link;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: @link-hover;
    }

    &:visited,
    &:active,
    &:focus {
        text-decoration: underline;
        color: @link-pressed;
    }
}


//---------------------------------------
//          Headings

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @font-family-secondary;
    font-weight: @font-weight-bold;
    color: var(--heading-colour, @brand-accent);
}

h1 {
    margin: 1.5rem 0 1rem;

    font-size: 1.875rem;
    line-height: 2rem;
}

h2 {
    margin: 1.5rem 0 0.75rem;

    font-size: 1.625rem;
    font-weight: @font-weight-bold;
    line-height: 1.75rem;
}

h3 {
    margin: 1.5rem 0 0.75rem;

    font-size: 1.125rem;
    font-weight: @font-weight-bold;
    line-height: 1.5rem;
}

h4 {
    margin: 1.5rem 0 0.75rem;

    font-size: 1rem;
    font-weight: @font-weight-bold;
    line-height: 1.4rem;
}

h5 {
    margin: 1rem 0 1rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
}

h6 {
    margin: 1rem 0 1rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
}

//---------------------------------------
//          Paragraph

p {
    .p-mixin;
}

//---------------------------------------
//          Codeblock

pre {
    background: @gray-10;
    border-radius: @border-radius;
    padding: @space-5;

    code {
        background: initial;
        border-radius: initial;
        padding: initial;
    }
}

code {
    background: @gray-10;
    border-radius: @border-radius;
    padding: 0 @space-2;
}

//---------------------------------------
//          Alignment

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

//---------------------------------------
//          Blockquote

blockquote {
    margin: 0;
    padding: @space-4;

    border-left: @space-2 solid;
    border-left-color: @brand-accent;

    >* {
        margin: 0px;
    }

}

//---------------------------------------
//          Labels

label {
    color: @brand-accent;
    font-size: var(--label-font-size, @font-size);
    line-height: var(--label-line-height, @line-height);
}


//---------------------------------------
//          Suffix

.suffix {
    color: @gray-60;
}


//---------------------------------------
//          Light

.light {
    color: @text-color-secondary;
}


//---------------------------------------
//          Media Querys

@media only screen and (max-width: 768px) {

    html {
        .type-sm-mixin();
    }

}

// Deprecated!!!!!
// Use key-value

//---------------------------------------
//          Fieldset

.fieldset--readyonly {
    display: flex;
    flex-direction: column;
}

div[class*='fieldset-item'] {
    display: flex;
    flex-direction: row;
    margin: @space-3 0;

    color: @text-color;

    >* {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

*[class*='fieldset-item']>span:first-child,
/** .fieldset-label deprecated */
.fieldset-label {
    margin-right: @space-3;

    font-family: @font-family-secondary;
    font-weight: bold;
    color: @brand-accent;
    font-size: @font-size;
    line-height: @line-height;
    word-break: break-word;
}

*[class*='fieldset-item']>span:last-child,
/** .fieldset-vale deprecated */
.fieldset-value {
    font-size: @font-size;
    line-height: @line-height;
    word-break: break-word;
}

*[class*='fieldset-item'][class*='--large'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        font-size: @font-size-lg;
        line-height: @line-height-lg;
    }
}

*[class*='fieldset-item'][class*='--small'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }
}

*[class*='fieldset-item'][class*='--x-small'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        margin-top: @space-2;
        margin-bottom: @space-2;

        font-size: @font-size-xs;
        line-height: @line-height-xs;
    }
}

*[class*='fieldset-item'][class*='--stacked'] {
    flex-direction: column;
}