@import (reference) './vars.less';

//---------------------------------------
//         Sizes

:root {
    --chip-background-colour: @gray-10;
    --chip-text-colour: @text-color;
}

.uf-chip.x-small {
    --avatar-size: @line-height-sm;
    --avatar-icon-size: @icon-size-xs;
    --avatar-font-size: @font-size-xs;

    --action-size: 1.5rem;
    --action-icon-size: @icon-size-xs;

    line-height: 1;
    padding-top: @space-2;
    padding-bottom: @space-2;

    > .uf-action {
        margin-top: -@space-2;
        margin-bottom: -@space-2;
        margin-right: -.75rem;
    }

    > uf-icon {
        margin-top: -0.175rem;
        margin-bottom: -0.175rem;
        margin-bottom: -125rem;
    }

    > .uf-avatar {
        margin-top: -0.175rem;
        margin-bottom: -0.175rem;
        margin-left: -0.75rem;
    }

}

//---------------------------------------
//         Colours

.uf-chip.primary {
    --chip-background-colour: @primary-action;
    --chip-text-colour: white;
    --action-icon-color: white;
    --action-hover-background: @primary-action-hover;
}

.uf-chip.secondary {
    --chip-background-colour: white;
    --chip-text-colour: @primary-action;
    --action-icon-color: @primary-action;

    box-shadow: inset 0 0 0 1px @primary-action;
}

.uf-chip.disabled {
    opacity: .8;
    pointer-events: none;
}

.uf-chip.outlined {
    --chip-background-colour: white;
    box-shadow: inset 0 0 0 1px @gray-20;
}

a.uf-chip:hover,
.uf-chip.focused,
.uf-chip.clickable:hover {
    --chip-background-colour: @gray-20;

    &.primary {
        --chip-background-colour: @primary-action-hover;
    }

    &.secondary {
        --chip-background-colour: @secondary-action-hover;
    }

    &.outlined {
        --chip-background-colour: @gray-10;
    }

    .uf-action:focused {
        --action-active-background: transparent;
    }

}

//---------------------------------------
//         Types

.uf-chip.clickable {
    cursor: pointer;
}

//---------------------------------------
//         Links

a.uf-chip{
    text-decoration: none;
    cursor: pointer;
}

//---------------------------------------
//         Base class

.uf-chip {
    display: flex;
    padding-left: @gutter;
    padding-right: @gutter;
    padding-top: @space-3;
    padding-bottom: @space-3;
    margin: @space-2 @space-3 @space-2 0;

    font-size: @font-size-sm;
    line-height: 1.2;
    color: var(--chip-text-colour);

    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--chip-background-colour);
    border-radius: @gutter;
    word-wrap: break-word;

    // Actions
    --action-size: 2rem;
    --action-icon-size: @icon-size-xs;
    --action-icon-color: @icon-color;
    --action-background: @tertiary-action;
    --action-hover-background: @gray-20;
    --action-box-shadow: none;

    // Avatars
    --avatar-size: 1.5rem;
    --avatar-icon-size: @icon-size-xs;
    --avatar-font-size: @font-size-xs;

    >uf-icon:last-child {
        margin-right: -@space-3;
        margin-top: -@space-3;
        margin-left: @space-3;
    }

    >.uf-action {
        margin-right: -@space-3;
        margin-top: -@space-3;
        margin-bottom: -@space-3;
        margin-left: @space-2;

        &:hover {
            background: transparent;
        }

        &:focus {
            background: @gray-20;
        }
    }

    .uf-avatar,
    >uf-icon:first-child {
        margin-left: -@space-3;
        margin-top: -@space-2;
        margin-bottom: -@space-3;
        margin-right: @space-2;
    }


}

