@import (reference) './vars.less';

:root,
.uf-box.default {
  --group-box-shadow: @box-shadow-lg;
  --group-background: white;
  --group-border-radius: 0px;
}

.uf-box.inset {
  --group-box-shadow: none;
  --group-background: @gray-10;
  --group-border-radius: @border-radius;
}

.uf-box.flat {
  --group-box-shadow: inset 0 0 0 1px @gray-10;
  --group-background: white;
  --group-border-radius: @border-radius;
}

.uf-box {
  // To ensure margins don't extend beyond container
  overflow: auto;

  box-shadow: var(--group-box-shadow);
  background: var(--group-background);
  border-radius: var(--group-border-radius);

  --app-app-bar-box-shadow: none;
  --app-bar-background: transparent;
  /** Needs to be transparent for box shadow to work */

  >.uf-box:not(.default):not(.flat):not(.inset) {
    --group-box-shadow: none;
    --group-background: transparent;
  }
}


// .uf-box--no-border,
// .uf-box--shadow--no-border {
//     . {
//         border: 0;
//     }
// }

// .uf-box--shadow,
// .uf-box--shadow--no-border,
// .uf-box--no-border--shadow {
//     box-shadow: @box-shadow-lg;
// }

// // Required when group with shadow is used with grid
// .uf-box--shadow--no-border.col-1of1,
// .uf-box--no-border--shadow.col-1of1 {
//     padding-left: 0;
//     padding-right: 0;
//     margin-right: @gutter/2;
//     margin-left: @gutter/2;
//     margin-bottom: @gutter;
// }

//---------------------------------------
//          Header

// .uf-header,
// .uf-header--small,
// .uf-header--large,
// .uf-header--accent,
// .uf-header--small--accent,
// .uf-header--accent--small,
// .uf-header--large--accent,
// .uf-header--accent--large {
//     display: flex;
//     padding: 0 @gutter/4;

//     >*:not(uf-expander-button) {
//         margin: auto @gutter/4;
//     }

//     uf-expander-button {
//         margin: auto 0 auto @gutter/4;
//     }

//     // title
//     .title,
//     .title--primary,
//     .title--clickable {
//         flex-grow: 1;

//         color: inherit;
//         font-family: @font-family-secondary;
//         font-weight: @font-weight-bold;

//         &:first-child {
//             margin-left: @gutter * .75;
//         }
//     }

//     .title--primary,
//     .title--clickable--primary,
//     .title--primary--clickable {
//         font-family: @font-family;
//         font-weight: normal;
//     }

//     .title--clickable,
//     .title--primary--clickable,
//     .title--clickable--primary {
//         cursor: pointer;
//     }

//     // Actions
//     .uf-action {

//         background: transparent;

//         uf-icon {
//             fill: @gray-80;
//         }

//         &:hover:not(:disabled) {
//             background: fade(black, 5%);
//         }

//         &:active:not(:disabled),
//         &:focus:not(:disabled) {
//             background: fade(black, 10%);
//         }

//         &:before {
//             content: initial;
//         }

//     }

// }

// // Default
// .uf-header,
// .uf-header--small,
// .uf-header--large {

//     background: @gray-10;

//     color: @text-color;

//     .uf-action,
//     .uf-action small,
//     .uf-action large,
//     .uf-action primary,
//     // Primary
//     .uf-action primary,
//     .uf-action small--primary,
//     .uf-action large--primary,
//     .uf-action primary--small,
//     .uf-action primary--large,
//     // Tertiary
//     .uf-action tertiary,
//     .uf-action small--tertiary,
//     .uf-action large--tertiary,
//     .uf-action tertiary--small,
//     .uf-action tertiary--large {

//         uf-icon {
//             fill: @gray-80;
//         }
//     }
// }

// // Accent
// .uf-header--accent,
// .uf-header--accent--large,
// .uf-header--large--accent,
// .uf-header--accent--small,
// .uf-header--small--accent {

//     background: @brand-accent;

//     color: white;

//     .uf-action,
//     .uf-action small,
//     .uf-action large,
//     .uf-action primary,
//     // Primary
//     .uf-action primary,
//     .uf-action small--primary,
//     .uf-action large--primary,
//     .uf-action primary--small,
//     .uf-action primary--large,
//     // Tertiary
//     .uf-action tertiary,
//     .uf-action small--tertiary,
//     .uf-action large--tertiary,
//     .uf-action tertiary--small,
//     .uf-action tertiary--large {

//         uf-icon {
//             fill: white;
//         }
//     }

//     uf-icon {
//         fill: white;
//     }

// }

// // Default size
// .uf-header,
// .uf-header--acccent {

//     .title,
//     .title--primary {
//         font-size: @font-size;
//         line-height: @line-height;
//     }
// }


// // Small
// .uf-header--small,
// .uf-header--accent--small,
// .uf-header--small--accent,
// .uf-header.small,
// .uf-header-accent.small {

//     min-height: @app-bar-height-xs;

//     .title,
//     .title--primary {
//         font-size: @font-size-sm;
//         line-height: @line-height-sm;
//     }

// }

// // Default size
// .uf-header,
// .uf-header--accent {
//     min-height: @app-bar-height-sm;

//     .title,
//     .title--primary {
//         font-size: @font-size-sm;
//         line-height: @line-height-sm;
//     }

// }

// // Large
// .uf-header--large,
// .uf-header--accent--large,
// .uf-header--large--accent {

//     min-height: @app-bar-height;

//     padding: 0 @gutter/2;

//     .title,
//     .title--primay {
//         font-size: @font-size-lg;
//         line-height: @line-height-lg;
//     }
// }

// .uf-header.clickable,
// .uf-header--small.clickable,
// .uf-header--large.clickable,
// .uf-header--accent.clickable,
// .uf-header--small--accent.clickable,
// .uf-header--accent--small.clickable,
// .uf-header--large--accent.clickable,
// .uf-header--accent--large.clickable {
//     cursor: pointer;
// }
