@import (reference) './vars.less';
@import (reference) './buttons.less';

.uf-button-group.tertiary {
    --button-group-border-colour: @gray-20;
}

//---------------------------------------
//    Button groups

.uf-button-group {

    display: flex;
    flex-shrink: 1;
    border-radius: @border-radius;
    overflow: hidden;

    border: 2px solid;
    border-color: var(--button-group-border-colour, @primary-action);

    .uf-button {
        margin: 0;
        flex: 1 1;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: initial;

        --button-box-shadow: none;

        border-radius: 0px !important;

        uf-icon {
            margin-left: @space-3;
        }
    }

    .uf-button:not(:last-child) {
        border-right: 2px solid var(--button-group-border-colour, @primary-action);
    }
}

.uf-button-group.small {
    border: 1px solid var(--button-group-border-colour, @primary-action);

    .uf-button {
        font-size: @font-size-sm;
        font-weight: normal;
    }

    .uf-button:not(:last-child) {
        border-right: 1px solid var(--button-group-border-colour, @primary-action);
    }
}

.uf-button-group.x-small {
    border: 1px solid var(--button-group-border-colour, @primary-action);

    .uf-button:not(:last-child) {
        border-right: 1px solid var(--button-group-border-colour, @primary-action);
    }
}