@import (reference) './vars.less';

/** Sizes **/

:root,
.default {
  // input
  --input-min-height: 2rem;
  --input-font-size: @font-size;
  --input-line-height: @line-height;
  --input-padding-left: 0;
  --input-padding-right: 0;
  --input-padding-top: .25rem;
  --input-padding-bottom: .25rem;

  // select
  --input-select-padding-right: 2rem;

  // .input-icon
  --input-input-icon-size: @icon-size;

  // .icon
  --input-icon-size: @icon-size-sm;

  // label
  --input-label-font-size: @font-size-sm;
  --input-label-line-height: @line-height-sm;
  --input-label-icon-size: @line-height-sm;

  // Radio
  --input-radio-label-font-size: @font-size-sm;
  --input-radio-label-line-height: @line-height;
  --input-radio-label-icon-size: @icon-size;

  // Checkbox
  --input-checkbox-label-font-size: @font-size-sm;
  --input-checkbox-label-line-height: @line-height;
  --input-checkbox-icon-size: @icon-size;

  // error
  --input-error-font-size: @font-size-xs;
  --input-error-line-height: @line-height-sm;
  --input-error-bottom: -1.25rem; // TODO fix less & css variables computation error -@line-height-sm

  // borders
  --input-border-bottom: @input-border--bottom;
}

.large {
  // input
  --input-min-height: 2.5rem;
  --input-font-size: @font-size;
  --input-line-height: @line-height;
  --input-padding-left: 0;
  --input-padding-right: 0;
  --input-padding-top: .5rem;
  --input-padding-bottom: .5rem;
  --input-radio-padding: @space-3 0 @space-3 0;

  // select
  --input-select-padding-right: 2rem;

  // .input-icon
  --input-input-icon-size: @icon-size;

  // .icon
  --input-icon-size: @icon-size-sm;

  // label
  --input-label-font-size: @font-size-sm;
  --input-label-line-height: @line-height-sm;
  --input-label-icon-size: @line-height-sm;

  // Radio
  --input-radio-label-font-size: @font-size;
  --input-radio-label-line-height: @line-height-lg;
  --input-radio-label-icon-size: @icon-size-lg;

  // Checkbox
  --input-checkbox-label-font-size: @font-size;
  --input-checkbox-label-line-height: @line-height;
  --input-checkbox-icon-size: @icon-size;

  // error
  --input-error-font-size: @font-size-sm;
  --input-error-line-height: @line-height-sm;
  --input-error-bottom: -1.25rem; // TODO fix less & css variables computation error -@line-height-sm
}

.small,
.x-small {
  // input
  --input-min-height: 1.5rem;
  --input-font-size: @font-size-sm;
  --input-line-height: @line-height-sm;
  --input-padding-left: 0;
  --input-padding-right: 0;
  --input-padding-top: 0.125rem;
  --input-padding-bottom: 0.125rem;
  --input-radio-paddingTop: @space-3;
  --input-radio-paddingBottom: @space-3;

  // select
  --input-select-padding-right: 1.375rem;

  // .input-icon
  --input-input-icon-size: @icon-size-sm;

  // .icon
  --input-icon-size: @icon-size-xs;

  // label
  --input-label-font-size: @font-size-xs;
  --input-label-line-height: @line-height-xs;
  --input-label-icon-size: @icon-size-xs;

  // Radio
  --input-radio-label-font-size: @font-size-xs;
  --input-radio-label-line-height: @line-height-sm;
  --input-radio-label-icon-size: @icon-size-sm;

  // Checkbox
  --input-checkbox-label-font-size: @font-size-xs;
  --input-checkbox-label-line-height: @line-height-sm;
  --input-checkbox-icon-size: @icon-size-sm;

  // error
  --input-error-font-size: @font-size-xs;
  --input-error-line-height: @line-height-xs;
  --input-error-bottom: -1rem;
}

/** Colours **/
:root {
  --help-text-colour: @gray-40;
}