@import (reference) './vars.less';

:root {
	--table-header-background-colour: white;
	--table-header-text-colour: @text-color;
}

.uf-table {
	--directory-min-height: 2.75rem;

	width: 100%;

	font-size: @font-size-sm;
	line-height: @line-height-sm;

	&.break-word {

		th,
		td {
			word-break: break-word;
		}
	}

	&.clickable {
		tbody tr {
			cursor: pointer;

			&:hover {
				--directory-indicator-bg: @gray-10;

				background-color: @gray-10;
			}
		}
	}

	&.nowrap {

		th,
		td {
			white-space: nowrap;
		}
	}

	th,
	td {
		padding: @gutter*.75 0 @gutter*.75 @gutter;

		&.no-padding {
			padding: 0;
		}

		font-weight: 400;
		text-align: left;
		word-wrap: break-word;

		&.empty {
			text-align: center;
		}

		&.bold {
			font-weight: bold;
		}

		&.italic {
			font-style: italic;
		}

		&.input-cell {
			padding: 0;

			--input-padding-left: @space-3;
			--checkbox-padding: @space-3 @gutter;

			// todo:
			// Temporary solutions until design finalised
			input,
			select {
				padding-left: @gutter;
				padding-right: 0;
			}
		}

		&.break-word {
			word-break: break-word;
		}
	}

	thead {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 1;

		background: var(--table-header-background-colour);

		font-family: @font-family-secondary;
		color: var(--table-header-text-colour);

		uf-help {
			margin-left: @space-3;
		}
	}

	tbody {
		background: white;

		tr {
			&:nth-child(odd) {
				--directory-indicator-bg: @gray-5;

				background: @gray-5;
			}

			td {
				uf-checkbox {
					padding-left: @space-3;
					padding-right: @space-3;
				}
			}
		}
	}
}

.uf-table.small th {
	padding: @gutter*.375 0 @gutter*.375 @gutter;
}

.uf-table.accent {

	thead {
		--table-header-background-colour: @brand-accent;
		--table-header-text-colour: white;

		color: white;

		border-left: 1px solid @brand-accent;
		border-right: 1px solid @brand-accent;

		uf-icon {
			fill: white !important;
		}
	}

	tbody {
		border-left: 1px solid @gray-10;
		border-right: 1px solid @gray-10;
		border-bottom: 1px solid @gray-10;
	}
}

.uf-table.outline {

	border: 1px solid @gray-10;

	td,
	th {
		position: relative;
	}

	th:after,
	td:after {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		height: 100%;
		width: 1px;
		background: @table-outline-color
	}
}