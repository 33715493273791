@import 'vars.less';

// ---------------------------
//          Padding

.generate-pad(~'', @gutter);
.generate-pad(-xs, @space-2);
.generate-pad(-sm, @space-3);
.generate-pad(-md, @gutter);
.generate-pad(-lg, @space-7);
.generate-pad(-xl, @space-10);
.generate-pad(-none, ~'0px !important');

.generate-pad(@property, @unit) {

    .pad@{property} {
        padding: @unit;
    }

    .pad@{property}-right {
        padding-right: @unit;
    }

    .pad@{property}-left {
        padding-left: @unit;
    }

    .pad@{property}-top {
        padding-top: @unit;
    }

    .pad@{property}-bottom {
        padding-bottom: @unit;
    }

    .pad@{property}-sides {
        padding-left: @unit;
        padding-right: @unit;
    }
}

// ---------------------------
//          Margin

.generate-gap(~'', @gutter);
.generate-gap(-xs, @space-1);
.generate-gap(-sm, @space-2);
.generate-gap(-md, @gutter);
.generate-gap(-lg, @space-7);
.generate-gap(-xl, @space-10);
.generate-gap(-none, ~'0px !important');

.generate-gap(@property, @unit) {

    .gap@{property} {
        margin: @unit;
    }

    .gap@{property}-right {
        margin-right: @unit;
    }

    .gap@{property}-left {
        margin-left: @unit;
    }

    .gap@{property}-top {
        margin-top: @unit;
    }

    .gap@{property}-bottom {
        margin-bottom: @unit;
    }

    .gap@{property}-sides {
        margin-left: @unit;
        margin-right: @unit;
    }
}


// ---------------------------
//          borders

.border-all {
    border: @border;
}

.border-top {
    border-top: @border;
}

.border-right {
    border-right: @border;
}

.border-bottom {
    border-bottom: @border;
}

.border-left {
    border-bottom: @border;
}



// ----------------------------
// Old Depracted  - DONT USE!!!!!
// ----------------------------

.padded {
    padding: @space-3;
}

.padded--big {
    padding: @gutter;
}

.padded--small {
    padding: @space-2;
}

.padded--vertical,
/** cheeky shorthand name */
.padded--vert {
    padding: @space-3 0;
}

.padded--horizontal,
/** cheeky shorthand name */
.padded--hor {
    padding: 0 @space-3;
}

.margin {
    margin: @space-3;
}

.margin--vertical {
    margin-top: @space-3;
    margin-bottom: @space-3;
}

.margin--vertical--large {
    margin: @gutter 0;
}

.margin--horizontal {
    margin-right: @space-3;
    margin-left: @space-3;
}

.margin--top {
    margin-top: @gutter;
}

.margin--bottom {
    margin-bottom: @gutter;
}

.margin--horizontal--large {
    margin-right: @gutter;
    margin-left: @gutter;
}