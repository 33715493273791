@import 'vars.less';

button.authentication-provider {
	display: flex;

	min-width: 7.5rem;
	padding: @space-3 @space-5;
	border: 1px solid @gray-40;

	align-items: center;
	align-content: flex-start;

	cursor: pointer;
	background: white;

	img {
		width: 20px;
		height: 20px;
		margin-right: 12px;
	}

	uf-icon {
		margin-right: 12px;
	}

	p {
		margin: 0;
		font-weight: 600;
	}

	&:hover {
		background: @gray-5;
	}
}