@import 'vars.less';

.uf-divider {
    width: 100%;
    border-top: 2px solid var(--divider-color, @gray-20);

    &.dashed{
        border-top: 2px dashed var(--divider-color, @gray-20);
    }

    &.dotted{
        border-top: 2px dotted var(--divider-color, @gray-20);
    }

    &.doubled{
        border-top: 4px double var(--divider-color, @gray-20);

        &.x-bold {
            border-top-width: 8px;
        }

        &.bold {
            border-top-width: 6px;
        }

        &.lite {
            border-top-width: 2px;
        }
    }

    &.space {
        border-color: transparent;
    }

    &.large {
        margin-top: @space-6;
        margin-bottom: @space-6;
    }

    &.medium {
        margin-top: @space-5;
        margin-bottom: @space-5;
    }

    &.small {
        margin-top: @space-3;
        margin-bottom: @space-3;
    }

    &.x-small {
        margin-top: @space-2;
        margin-bottom: @space-2;
    }

    &.x-bold {
        border-top-width: 5px;
    }

    &.bold {
        border-top-width: 3px;
    }

    &.lite {
        border-top-width: 1px;
    }
}

.uf-divider-vertical {

    align-self: normal;

    border-left: 2px solid var(--divider-color, @gray-20);

    &.dashed{
        border-left: 2px dashed var(--divider-color, @gray-20);
    }

    &.dotted{
        border-left: 2px dotted var(--divider-color, @gray-20);
    }

    &.doubled{
        border-left: 4px double var(--divider-color, @gray-20);


        &.x-bold {
            border-left-width: 8px;
        }

        &.bold {
            border-left-width: 6px;
        }

        &.lite {
            border-left-width: 2px;
        }
    }

    &.space {
        border-color: transparent;
    }

    &.large {
        margin-left: @space-6;
        margin-right: @space-6;
    }

    &.medium {
        margin-left: @space-5;
        margin-right: @space-5;
    }

    &.small {
        margin-left: @space-3;
        margin-right: @space-3;
    }

    &.x-small {
        margin-left: @space-2;
        margin-right: @space-2;
    }

    &.x-bold {
        border-left-width: 5px;
    }

    &.bold {
        border-left-width: 3px;
    }

    &.lite {
        border-left-width: 1px;
    }

}
