@import 'vars.less';

//------------------------------------
//          Grid

.uf-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: @gutter;
    grid-row-gap: @gutter;

    // Column gaps
    &.condensed {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }

    // Row gaps

    &.row-gap-xs {
        grid-row-gap: @space-2; // 4px
    }

    &.row-gap-sm {
        grid-row-gap: @space-4;
    }

    &.row-gap {
        grid-row-gap: @space-5; // 16px
    }

    &.row-gap-lg {
        grid-row-gap: @space-6; //
    }

    .row-gap-xl {
        grid-row-gap: @space-7;
    }

    // Column gaps

    &.column-gap-xs {
        grid-column-gap: @space-2; // 4px
    }

    &.column-gap-sm {
        grid-column-gap: @space-4;
    }

    &.column-gap {
        grid-column-gap: @space-5; // 16px
    }

    &.column-gap-lg {
        grid-column-gap: @space-6; //
    }

    &.column-gap-xl {
        grid-column-gap: @space-7;
    }

}

.generate-stretch-columns(xxl);
.generate-stretch-columns(xl);
.generate-stretch-columns(lg);
.generate-stretch-columns(md);

.generate-base-columns();
.generate-columns(sm);
.generate-order(sm);

//------------------------------------
//          Break points

@media (min-width: @screen-width-md) {
    .generate-columns(md);
    .generate-order(md);
}

@media (min-width: @screen-width-lg) {
    .generate-columns(lg);
    .generate-order(lg);
}

@media (min-width: @screen-width-xl) {
    .generate-columns(xl);
    .generate-order(lx);
}

@media (min-width: @screen-width-xxl) {
    .generate-columns(xxl);
    .generate-order(xxl);
}


//------------------------------------
//          Mixins

.generate-base-columns(@multiplier: 1, @i: 1) when (@i =< 12) {

    .col-@{i} {
        grid-column: span (@i * @multiplier);
        min-width: 0; // needed for Firefox overflow behavior
    }

    .generate-base-columns(@multiplier, (@i + 1));
    // Outputs .col-1 - .col-12
}

.generate-columns(@breakpoint, @multiplier: 1, @i: 1) when (@i =< 12) {

    .col-@{breakpoint}-@{i} {
        grid-column: span (@i * @multiplier);
        min-width: 0; // needed for Firefox overflow behavior
    }

    .generate-columns(@breakpoint, @multiplier, (@i + 1));
    // Outputs .col-[md]-1 - .col-[md]-12
}

.generate-stretch-columns(@breakpoint, @i: 1) when (@i =< 12) {

    .col-@{breakpoint}-@{i} {
        grid-column: span 12;
        min-width: 0; // needed for Firefox overflow behavior
    }

    .generate-stretch-columns(@breakpoint, (@i + 1));
}

.generate-hidden(@size) {
    .hide-@{size} {
        display: none !important;
    }
}

.generate-order(@breakpoint, @multiplier: 1, @i: 1) when (@i =< 12) {

    .order-@{breakpoint}-@{i} {
        order: @i * @multiplier;
    }

    .generate-order(@breakpoint, @multiplier, (@i + 1));
    // Outputs .order-[md]-1 - .order-[md]-12
}

//------------------------------------
//          Adjust Text margins

// .uf-grid.pad,
// .uf-grid.pad-lg,
// .uf-grid.pad-sm,
// .uf-grid.pad-xs {

//     >.col-1:first-child,
//     >.col-2:first-child,
//     >.col-3:first-child,
//     >.col-4:first-child,
//     >.col-5:first-child,
//     >.col-6:first-child,
//     >.col-7:first-child,
//     >.col-8:first-child,
//     >.col-9:first-child,
//     >.col-10:first-child,
//     >.col-11:first-child,
//     >.col-12:first-child {

//         > h1:first-child,
//         > h2:first-child,
//         > h3:first-child,
//         > h4:first-child,
//         > h5:first-child,
//         > h6:first-child,
//         > p:first-child {
//             margin-top: .5rem;
//         }
//     }

//     >.col-1,
//     >.col-2,
//     >.col-3,
//     >.col-4,
//     >.col-5,
//     >.col-6,
//     >.col-7,
//     >.col-8,
//     >.col-9,
//     >.col-10,
//     >.col-11,
//     >.col-12 {

//         > h1:last-child,
//         > h2:last-child,
//         > h3:last-child,
//         > h4:last-child,
//         > h5:last-child,
//         > h6:last-child,
//         > p:last-child {
//             margin-bottom: .5rem;
//         }
//     }

// }


/**
    OLD GRID DEPRECATED
*/

//------------------------------------
// CSS Vars

:root {
    --compact-grid-padding: 0.625rem; // (@line-height - @line-height-sm + @gutter)
    --compact-grid-margin: 0.625rem; // (@line-height - @line-height-sm + @gutter)
}

.small {
    --compact-grid-padding: 0.25rem;
    --compact-grid-margin: 0.25rem;
}

*[class*='col-1of1'],
*[class*='col-1of2'],
*[class*='col-1of3'],
*[class*='col-2of3'],
*[class*='col-1of4'],
*[class*='col-3of4'],
*[class*='col-1of5'],
*[class*='col-1of8'] {
    width: 100%;
    padding: 0 @space-3;
    box-sizing: border-box;
    margin-top: @gutter;

    &:not([class*='--no-margin']) {

        // fake margin collapse
        &>h1,
        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6 {
            &:first-child {
                margin-top: 0.5rem;
            }
        }

        &>p,
        &>ul {
            &:first-child {
                margin-top: 0;
            }
        }

    }

    &[class*='--compact'] {
        padding: 0 var(--compact-grid-padding) 0 0;
        margin-top: var(--compact-grid-margin);
    }

    &[class*='--no-padding'] {
        padding: 0;
    }

    &[class*='--no-margin'] {
        margin: 0;
    }
}

// // New Grid

.fixed-container {
    margin: 0 auto;
}

.grid {
    padding-left: @space-3;
    padding-right: @space-3;
}

*[class*='grid']:not(.uf-grid),
*[class*='grid--fixed']:not(.uf-grid) {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: @gutter;

    &[class*='--compact'] {
        padding: 0 0 var(--compact-grid-margin) 0;
    }

    &[class*='--no-padding'] {
        padding: 0;
    }
}

@media (max-width: @mobile-screen) {

    *[class*='grid--fixed']:not(.uf-grid),
    .fixed-container {

        *[class*='col-1of1'],
        *[class*='col-1of2'],
        *[class*='col-1of3'],
        *[class*='col-2of3'],
        *[class*='col-1of4'],
        *[class*='col-3of4'],
        *[class*='col-1of5'],
        *[class*='col-1of8'] {
            max-width: @mobile-screen;
        }
    }
}


@media (min-width: @desktop-screen) {

    *[class*='col-1of1'] {
        width: 100%;
    }

    *[class*='col-1of2'] {
        width: 50%;
    }

    *[class*='col-1of3'] {
        width: 33.3333334%;
    }

    *[class*='col-2of3'] {
        width: 66.666666%;
    }

    *[class*='col-1of4'] {
        width: 25%;
    }

    *[class*='col-3of4'] {
        width: 75%;
    }

    *[class*='col-1of5'] {
        width: 20%;
    }

    *[class*='col-1of8'] {
        width: 12.5%;
    }

    .fixed-container {
        max-width: @fixed-grid-width--small;
    }

    *[class*='grid--fixed']:not(.uf-grid) {
        max-width: @fixed-grid-width--small;
        margin: 0 auto;

        >*[class*='col-1of1'] {
            width: @fixed-grid-width--small;
        }

        >*[class*='col-1of2'] {
            width: @fixed-grid-width--small/2;
        }

        >*[class*='col-1of3'] {
            width: @fixed-grid-width--small/3;
        }

        >*[class*='col-1of4'] {
            width: @fixed-grid-width--small/4;
        }

        >*[class*='col-3of4'] {
            width: @fixed-grid-width--small*3/4;
        }

        >*[class*='col-1of5'] {
            width: @fixed-grid-width--small/5;
        }

        >*[class*='col-1of8'] {
            width: @fixed-grid-width--small/8;
        }
    }
}

@media (min-width: @desktop-screen--large) {

    .fixed-container {
        max-width: @fixed-grid-width;
    }

    *[class*='grid--fixed']:not(.uf-grid) {
        max-width: @fixed-grid-width;
        margin: 0 auto;

        >*[class*='col-1of1'] {
            width: @fixed-grid-width;
        }

        >*[class*='col-1of2'] {
            width: @fixed-grid-width/2;
        }

        >*[class*='col-1of3'] {
            width: @fixed-grid-width/3;
        }

        >*[class*='col-1of4'] {
            width: @fixed-grid-width/4;
        }

        >*[class*='col-3of4'] {
            width: @fixed-grid-width*3/4;
        }

        >*[class*='col-1of5'] {
            width: @fixed-grid-width/5;
        }

        >*[class*='col-1of8'] {
            width: @fixed-grid-width/8;
        }
    }
}

@media print {

    *[class*='col-1of1'] {
        width: 100%;
    }

    *[class*='col-1of2'] {
        width: 50%;
    }

    *[class*='col-1of3'] {
        width: 33.3333334%;
    }

    *[class*='col-2of3'] {
        width: 66.666666%;
    }

    *[class*='col-1of4'] {
        width: 25%;
    }

    *[class*='col-1of5'] {
        width: 20%;
    }

    *[class*='col-1of8'] {
        width: 12.5%;
    }
}