@import (reference) './vars.less';

// --------------------------------
//         Size / Spacing Modifiers

:root,
.default {
	--list-item-padding: 0.75rem 1rem; // @gutter*.75rem @gutter;
	--list-item-negative-margin: -0.75rem -1rem;
	--list-item-min-height: 2.25rem; // @line-height + @gutter*.75rem;

	--icon-size: @line-height;

	--font-size: @font-size;
	--line-height: @line-height;
}

.large {
	--list-item-padding: 1rem;
	--list-item-negative-margin: -1rem;
	--list-item-min-height: 4rem; // @line-height + @gutter*2;

	--icon-size: @line-height-lg;

	--font-size: @font-size-lg;
	--line-height: @line-height-lg;
}

.small {
	--list-item-padding: 0.25rem 0.5rem;
	--list-item-negative-margin: -0.25rem -0.5rem;
	--list-item-min-height: 2.25rem;

	--icon-size: @line-height-sm;

	--font-size: @font-size-sm;
	--line-height: @line-height-sm;
}

.x-small {
	--list-item-padding: 0.125rem 0.5rem;
	--list-item-negative-margin: -0.125rem -0.5rem;
	--list-item-min-height: 1.375rem; // @line-height-sm + (@gutter/2)

	--icon-size: @line-height-xs;

	--font-size: @font-size-xs;
	--line-height: @line-height-xs;
}

// --------------------------------
//       Reuseable

.list-reset() {
	margin: 0;
	padding: 0;

	overflow: auto;
	-webkit-overflow-scrolling: touch;

	list-style: none;
}

.list() {
	color: @text-color;

	a {
		// .a-mixin;
	}
}


// --------------------------------
//        list Elements

.uf-list-item,
.uf-list-button,
.uf-list-button>button,
.uf-list-link a,
a.uf-list-link {
	display: flex;
	align-items: center;
	flex-basis: 0px;

	padding: var(--list-item-padding);
	margin: 0;
	min-height: var(--list-item-min-height);

	border-top: @border;
	border-bottom: @border;

	font-size: var(--font-size, @font-size);
	line-height: var(--line-heigth, @line-height);
	color: @text-color;

	+li,
	+.uf-list-item,
	+.uf-list-button,
	+a.uf-list-link,
	+.uf-list-link a {
		border-top: none;
	}

	>*:not(:last-child) {
		margin-right: @gutter*.75rem;
	}

	>*:not(.shrink) {
		flex-shrink: 0; // default to no shrinkage
	}

	.multi-text {
		display: block;
		flex-grow: 1;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	uf-icon {
		flex-shrink: 0;
	}
}

.uf-list-button>button {
	flex: 1;
	margin: var(--list-item-negative-margin);

	border: 0;
	background-color: transparent;

	text-align: start;

	&:focus {
		outline: 0;
	}
}

.uf-list-button,
a.uf-list-link,
.uf-list-link a {
	cursor: pointer;

	text-decoration: none;

	&:hover:not(.disabled) {
		background-color: @gray-10
	}

	&.active:not(.disabled) {
		background-color: var(--list-active-color, @gray-10);
	}

	&.disabled {
		cursor: initial;
	}
}

.uf-list-button.block {
	display: block;
}


/**
@deprecated -
Use basic classes (such as .row and .grow) to build your container like in the example.
Style's may vary slighty between usuages so makes sense to compose this element as you see fit
*/
.uf-list-fieldset {
	display: flex;

	>.uf-grid {
		flex-grow: 1;
		grid-gap: @gutter;
		padding: @gutter 0 @gutter @gutter;

		&:last-child {
			padding-right: @gutter;
		}
	}

	>.uf-app-bar {
		padding: 0;
	}
}



// --------------------------------
//       List

.uf-list {
	.list-reset;
	.list;

	background: white;

	color: @text-color;

	>li {
		border-top: @border;
		border-bottom: @border;
	}

	>li+li {
		border-top: none;
	}

	>li:last-child {
		border-bottom: @border;
	}

	&.transparent {
		background: initial;
	}

}

// --------------------------------
//       Color

.uf-list.accent {

	border-top: 2px solid @brand-accent;

	>.uf-list-link,
	>li {
		border-width: 2px;
		border-color: @brand-accent;
	}
}

// --------------------------------
//       Groups

.uf-list.uf-box {

	>li:first-child,
	>.uf-list-item:first-child,
	>.uf-list-button:first-child,
	>.uf-list-link:first-child,
	>.uf-list-fieldset:first-child {
		border-top: none;
	}

	>li:last-child,
	>.uf-list-item:last-child,
	>.uf-list-button:last-child,
	>.uf-list-link:last-child,
	>.uf-list-fieldset:last-child {
		border-bottom: none;
	}
}


// --------------------------------
//       Navigation list

.uf-nav-list {
	.list-reset;

	overflow: hidden;

	border: var(--list-border, none);
	border-radius: var(--list-border-radius, @border-radius);

	--inherit-font-family: @font-family;
	--inherit-font-weight: initial;
}

.uf-nav-list a {
	display: flex;
	align-items: center;

	margin: var(--listSpacing, 0);
	padding: 0 @gutter*.75 0 @gutter*.75;
	overflow: var(--listOverflow, auto);
	min-height: var(--list-item-min-height, @line-height + @gutter);
	border-radius: var(--list-border-radius, @border-radius);

	cursor: pointer;

	text-overflow: ellipsis;
	white-space: nowrap;
	text-decoration: none;
	color: @text-color;
	font-size: var(--font-size, @font-size);
	line-height: var(--line-heigth, @line-height);

	&:hover {
		background-color: @primary-action-hover;

		color: white;
		text-decoration: none;
	}

	&.active {
		background: @primary-action;
		color: white;
	}

}

// Box autocomplete

// .uf-list-box {
//     .uf-list;

//     border-radius: @border-radius;
//     box-shadow: @box-shadow-lg;

//     --list-border: none;
//     --listPadding: @gutter/2 @gutter;
//     --listOverflow: hidden;

//     overflow: hidden;

//     > li {
//         padding: var(--listPadding, 0 @gutter 0 @gutter);

//         cursor: pointer;

//         text-overflow: ellipsis;
//         white-space: nowrap;

//         &:hover {
//             background-color: @gray-10
//         }
//     }

// }