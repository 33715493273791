@import (reference) './vars.less';

//------------------------------------------
// Force white background if used with panel

uf-panel.uf-card,
uf-panel.uf-form-card {
    --panel-background-colour: white;
}


//-------------------------------------
//          Card

.uf-card,
.uf-card--centered {
    display: block;
    overflow: hidden;
    border-radius: @border-radius;
    background: white;
    box-shadow: @box-shadow;

    .uf-app-bar.flat {
        display: block;

        .uf-action {
            float: right;
        }

        h3,
        h4 {
            margin-left: @space-4;
            margin-bottom: @space-4;
            padding-top: @space-5;
        }
    }

}

//-------------------------------------
//             links

a.uf-card,
a.uf-card--centered {
    cursor: pointer;

    text-decoration: none;

    transition: all .1s ease-in-out;

    &:hover {
        box-shadow: @box-shadow-lg;
    }
}

.uf-card.centered,
.uf-card--centered {
    text-align: center;

    .uf-card-title {
        justify-content: center;
    }
}

//-------------------------------------
//             Card title

// Used for centering and keeping labels consistant

.uf-card-title {
    display: flex;
    min-height: @gutter*3.5rem;
    align-items: center;
    padding: 0 @gutter;

    >* {
        margin: 0;
    }
}

//-------------------------------------
//             Form Card

// was originally content card

.uf-form-card {
    display: flex;
    flex-direction: column;
    position: relative;

    background: white;
    box-shadow: @box-shadow-lg;

    &:before {
        content: '';
        display: block;
        z-index: 1;

        border-top: @space-2 solid;
        border-top-color: @brand-accent;
    }

    .uf-app-bar.flat {
        display: block;

        .uf-action {
            float: right;
        }

        h3 {
            margin-left: @space-4;
            margin-bottom: @space-4;
            padding-top: @space-5;
        }
    }

    uf-progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        z-index: 2;
    }

}

.uf-form-card.no-shadow {
    box-shadow: none;
}


//-------------------------------------
//             Card Groups

.uf-card-list {
    display: grid;
    // todo: create modifier for stretched cards
    // grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-template-columns: repeat(auto-fill, 224px);
    justify-content: center;
    grid-gap: @space-7;
    align-items: stretch;
}


@media (max-width: @mobile-screen) {

    .uf-card-title>* {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }

}

//-------------------------------------
//            App Bar

/**
 todo: fix this!!! all app-bars shouldn't be effected!!!
 Create form-card header class which extends app-bar class
 */
.uf-card .uf-app-bar:not(.medium),
.uf-form-card .uf-app-bar:not(.medium) {
    display: block;

    box-shadow: none;

    .uf-action {
        margin-top: @space-2;
        float: right;
    }

    h3 {
        margin-left: @space-4;
        margin-bottom: @space-4;
        padding-top: @space-5;
    }
}

.uf-card .uf-form-actions,
.uf-form-card .uf-form-actions {
    margin-top: auto;
}

//-------------------------------------
//           Horizontal Card


.uf-horizontal-card {
    display: flex;
    overflow: hidden;

    background: white;
    box-shadow: @box-shadow;
    border-radius: @border-radius;

    border-left: @space-2 solid @brand-accent;

    .icon {
        padding: @space-3;

        uf-icon {
            fill: @brand-accent;
        }
    }

    .content {
        display: flex;
        align-items: center;
    }

    &.info {
        border-left-color: @info;

        .icon uf-icon {
            fill: @info;
        }
    }

    &.error {
        border-left-color: @error;

        .icon uf-icon {
            fill: @error;
        }
    }

    &.success {
        border-left-color: @success;

        .icon uf-icon {
            fill: @success;
        }
    }

    &.warning {
        border-left-color: @warning;

        .icon uf-icon {
            fill: @warning;
        }
    }

}


//-------------------------------------
//            Deprecated


a.uf-content-card,
// Deprecated use form card
a.uf-content-card--centered,
// Deprecated use form card
a.uf-product-card,
a.uf-product-card--centered {
    text-decoration: none;
    color: @text-color;

    transition: all .1s ease-in-out;

    &:hover {
        box-shadow: @box-shadow-lg;
    }

    &[class*='--placeholder'] {
        opacity: 0;

        &:hover {
            opacity: 0.5;
            box-shadow: @box-shadow;
        }
    }
}

// Deprecated use uf-card

.uf-product-card,
.uf-product-card--centered {
    background: white;
    box-shadow: @box-shadow;

    border-radius: @border-radius;
    overflow: hidden;
}

.uf-content-card,
.uf-content-card--centered {
    background: white;
    box-shadow: @box-shadow;

    border-top: @space-2 solid;
    border-top-color: @brand-accent;
}

/** Reset padding if in column */

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {

    >.uf-card-title {
        padding: 0;
    }

}