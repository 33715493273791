@import (reference) './vars.less';

/**
    Class Reference

    base: .uf-button;
    modifiers: large, small, primary, tertiary

    Usage
    <button class="uf-button large"></button>
    <button class="uf-button large primary"></button>
*/

/** TODO find usages of:
button-mixin()
button--large()
button--default-size()
button--small()
button--x-small()
button--primary()
button--secondary()
button--tertiary()

**/

//---------------------------------------
//         Sizes

:root,
.default {
	--button-min-width: 120px;
	--button-min-height: 40px;

	--button-padding: @space-3 @gutter;
	--button-link-padding: .625rem @gutter;

	--button-font-size: @font-size-sm;
	--button-line-height: @line-height-sm;
}

.large {
	--button-min-width: 140px;
	--button-min-height: @space-9; // @gutter*2 + @gutter

	--button-padding: @space-3 @gutter;
	--button-link-padding: @space-4 @gutter;

	--button-font-size: @font-size;
	--button-line-height: @line-height;
}

.small {
	--button-min-width: 100px;
	--button-min-height: 32px;

	--button-padding: @space-2 @gutter; // @gutter/4 @gutter
	--button-link-padding: .375rem @gutter;

	--button-font-size: @font-size-sm;
	--button-line-height: @line-height-sm;
}

.x-small {
	--button-min-width: 60px;
	--button-min-height: auto;

	--button-padding: @space-2 @space-3;
	--button-link-padding: @space-2 @space-3;

	--button-font-size: @font-size-sm;
	--button-line-height: @line-height-sm;
}

//---------------------------------------
//         Colours

:root,
.uf-button.default,
.uf-button.secondary {
	--button-background: @secondary-action;
	--button-box-shadow: inset 0 0 0 2px @primary-action, @box-shadow;
	--button-color: @primary-action;

	--button-disabled-background: @secondary-action-disabled;
	--button-hover-background: @secondary-action-hover;
	--button-active-background: @secondary-action-pressed;
}

.uf-button.primary {
	--button-background: @primary-action;
	--button-box-shadow: @box-shadow;
	--button-color: white;

	--button-disabled-background: @primary-action-disabled;
	--button-hover-background: @primary-action-hover;
	--button-active-background: @primary-action-pressed;
}

.uf-button.tertiary {
	--button-background: transparent;
	--button-box-shadow: none;
	--button-color: @primary-action;

	--button-disabled-background: @tertiary-action-disabled;
	--button-hover-background: @tertiary-action-hover;
	--button-active-background: @tertiary-action-pressed;
}

//---------------------------------------
//          Disabled

.uf-button:disabled,
.uf-button.default:disabled,
.uf-button.secondary:disabled {
	--button-color: @primary-action-disabled;
	--button-box-shadow: inset 0 0 0 2px @primary-action-disabled, @box-shadow;
}

.uf-button.tertiary:disabled {
	--button-color: @primary-action-disabled;
	--button-box-shadow: none;
}

.uf-button.primary:disabled {
	--button-color: white;
	--button-box-shadow: none;
}

//---------------------------------------
//          Alignement

.uf-button.align-left {
	--button-min-width: auto;

	text-align: left;
	padding-left: @space-3;

	uf-icon:first-child {
		margin-left: 0;
	}
}

.uf-button.align-right {
	--button-min-width: auto;

	text-align: left;
	padding-right: @space-3;

	uf-icon:last-child {
		margin-left: 0;
	}
}

//---------------------------------------
//         Default

.uf-button {
	position: relative; // Required For
	display: block;
	min-width: var(--button-min-width);
	min-height: var(--button-min-height);
	padding: var(--button-padding);

	max-width: @column-width*3;

	background: var(--button-background);
	border-color: none;
	border-width: 0px;
	border-radius: @border-radius-button;
	-webkit-tap-highlight-color: transparent;
	outline: 0;
	box-shadow: var(--button-box-shadow);

	cursor: pointer;

	font-size: var(--button-font-size);
	line-height: var(--button-line-height);
	font-weight: var(--button-font-weight, 600);
	text-align: center;
	text-decoration: none;
	color: var(--button-color);

	uf-icon:not(.uf-badge) {
		display: inline-block;
		vertical-align: bottom;

		width: var(--button-line-height);
		height: var(--button-line-height);
		fill: var(--button-color);

		&:not(.left) {
			margin-left: @space-3;
		}
	}

	&:disabled {
		background: var(--button-disabled-background);
	}

	&:hover:not(:disabled) {
		background: var(--button-hover-background);
	}

	&:active:not(:disabled),
	&:focus:not(:disabled) {
		background: var(--button-active-background);
	}

	// Badge
	>.uf-badge {
		position: absolute;
		right: -6px;
		top: -6px;
	}

}

a.uf-button {
	padding: var(--button-link-padding);
}