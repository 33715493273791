@import (reference) './vars.less';
@import (reference) './typography.less';


//---------------------------------------
//          Body copy

.body-copy {

	ul {
		.ul-mixin;
	}

	ol {
		.ul-mixin;
	}

	li {
		.li-mixin;
	}

	img[data-position='stretch'],
	video[data-position='stretch'] {
		clear: both;
		display: block;
		width: 100%;
	}

	img[data-position='floatRight'],
	video[data-position='floatRight'] {
		clear: both;
		float: right;
		margin-left: 1rem;
		margin-bottom: 1rem;
	}

	img[data-position='floatLeft'],
	video[data-position='floatLeft'] {
		clear: both;
		float: left;
		margin-right: 1rem;
		margin-bottom: 1rem;
	}

	img[data-position='center'],
	video[data-position='center'] {
		display: block;
		clear: both;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
	}

	img[data-position='right'],
	video[data-position='right'] {
		display: block;
		clear: both;
		margin-left:auto;
		margin-bottom: 1rem;
	}

	img[data-position='left'],
	video[data-position='left'] {
		display: block;
		clear: both;
		margin-right: auto;
		margin-bottom: 1rem;
	}

	img[data-width='33%'] {
		width: 33%;
	}

	img[data-width='50%'] {
		width: 50%;
	}
}


//---------------------------------------
//          links

.body-copy a:not(.uf-button),
p a:not(.uf-button) {
	.a-mixin;
}


//---------------------------------------
//          list

ul.body-copy,
ol.body-copy {
	.ul-mixin;

	li {
		.li-mixin;
	}
}

.body-copy // Experimental see if global .compact is ok, otherwise use body-copy with compact modifier
.compact,
.body-copy--compact,
.body-copy.compact {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-top: @space-2;
		margin-bottom: @space-2;
	}

	.fieldset-item,
	.fieldset-item--large,
	.fieldset-item--small,
	.fieldset-item--x-small {
		margin-top: 0;
		margin-bottom: 0;
	}

}

@media only screen and (max-width: @screen-width-sm) {

	.body-copy {
		img[data-position='floatRight'],
		video[data-position='floatRight'],
		img[data-position='floatLeft'],
		video[data-position='floatLeft'],
		img[data-position='left'],
		video[data-position='left'],
		img[data-position='right'],
		video[data-position='right'],
		img[data-position='center'],
		video[data-position='center'],
		img[data-position='stretch'],
		video[data-position='stretch'] {
			display: block;
			width: 100% !important;
			margin: 0;
		}
	}
}
