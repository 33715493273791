@import (reference) './vars.less';

.x-small-mixin() {
	display: inline-block;
	font-size: 0;
	border-radius: 100%;
	text-align: center;
	width: 1.25rem;
	height: 1.25rem;
	text-transform: uppercase;

	&:first-letter {
		font-size: @font-size-xs;
	}
}

.uf-lozenge {
	display: inline;
	padding: @space-1 @space-2;

	border-radius: @border-radius;
	background: @gray-80;

	color: white !important;
	font-size: @font-size-xs;
	line-height: @line-height-xs;
	font-weight: bold;
	font-style: normal;
}

.uf-lozenge.x-small {
	.x-small-mixin();
}

.uf-lozenge.no-wrap {
	white-space: nowrap;
}

//---------------------------------------
//         Background colour

.uf-lozenge.default {
	background: @gray-80;
}

.uf-lozenge.info {
	background: @info;
}

.uf-lozenge.success {
	background: @success;
}

.uf-lozenge.accent {
	background: @brand-accent;
}

.uf-lozenge.warning {
	background: @warning;
}

.uf-lozenge.error {
	background: @error;
}

.uf-lozenge.disabled {
	background: @gray-20;
}

@media screen and (max-width: @screen-width-sm) {
	.generate-lozenge-breakpoint(sm);
}

@media screen and (max-width: @screen-width-md) {
	.generate-lozenge-breakpoint(md);
}

.generate-lozenge-breakpoint(@breakpoint) {
	.uf-lozenge.x-small-@{breakpoint} {
		.x-small-mixin();
	}
}