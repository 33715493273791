@import (reference) './vars.less';

//---------------------------------------
//         Sizes

:root,
.default {
    --app-bar-height: @app-bar-height;
    --app-bar-font-size: @font-size;
}

.medium {
    --app-bar-height: @app-bar-height-sm;
    --app-bar-font-size: @font-size;
}

.small {
    --app-bar-height: @app-bar-height-xs;
    --app-bar-font-size: @font-size-sm;
}

//---------------------------------------
//         Depth

:root {
    --app-app-bar-box-shadow: @box-shadow;
}

.uf-app-bar.flat {
    --app-app-bar-box-shadow: none;
}

//---------------------------------------
//         Colours

:root,
.uf-app-bar.default {
    --app-bar-background: white;
    --app-bar-text-colour: @text-color;
    --app-bar-icon-colour: @text-color;
}

.uf-app-bar.accent {
    --app-bar-background: @brand-accent;
    --app-bar-text-colour: white;
    --app-bar-icon-colour: white;
    --icon-color: white;
    --input-icon-colour: white;

    input,
    .value select:not(.disabled) {
        color: white !important;
    }

    ::placeholder {
        color: @gray-5;
        /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: @gray-5;
    }

}

.uf-app-bar.transparent {
    --app-bar-background: transparent;
    --app-bar-text-colour: @text-color;
    --app-bar-icon-colour: @text-color;
    --app-app-bar-box-shadow: none;
}


//---------------------------------------
//          App Bar

.uf-app-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 @space-2;
    min-height: var(--app-bar-height);

    background: var(--app-bar-background);
    box-shadow: var(--app-app-bar-box-shadow);

    color: var(--app-bar-text-colour, @text-color);

    // Everything thats not an action
    >* {
        margin: auto @space-2;
    }

    // Smaller margin required for actions
    >.uf-action:not(:first-child) {
        margin-left: @space-1;
        margin-right: @space-1;
    }

    >.uf-action:last-child {
        margin-right: @space-2;
    }

    >uf-icon {
        flex-shrink: 0;
    }

    .title {
        display: flex;
        flex-grow: 1;

        font-size: var(--app-bar-font-size);
        color: inherit;
        font-family: @font-family-secondary;
        font-weight: @font-weight-bold;
        text-align: left;

        overflow: hidden;
        word-break: break-word;
        align-items: center;

        &:first-child {
            margin-left: @gutter * .75;
        }

        &>* {
            margin-left: @gutter * .5;
        }
    }

    uf-breadcrumbs:first-child {
        display: flex;
        flex-grow: 1;
    }

    .title.primary {
        font-family: @font-family;
        font-weight: normal;
    }

    // Set all actions to tertiary action style
    .uf-action:not(.default) {
        --action-background: @tertiary-action;
        --action-box-shadow: none;

        // state
        --action-disabled-background: none;
        --action-hover-background: @tertiary-action-hover;
        --action-active-background: @tertiary-action-pressed;

        uf-icon {
            fill: var(--app-bar-icon-colour, @icon-color);
        }

        &:disabled>uf-icon {
            fill: @gray-40;
        }

        &:before {
            content: initial;
        }
    }

    uf-expander-button:first-child {
        margin: auto 0;

        +.title {
            margin-left: 0;
        }
    }

}


//---------------------------------------
//          App Bar Divider

.uf-app-bar {
    >.uf-divider-vertical {
        min-height: @app-bar-height - @gutter;
    }
}

.uf-app-bar.medium {
    >.uf-divider-vertical {
        min-height: @app-bar-height-sm - @gutter;
    }
}

.uf-app-bar.small {
    >.uf-divider-vertical {
        min-height: @app-bar-height-xs - @gutter;
    }
}